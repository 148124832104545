import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import withRoot from '../withRoot';
import Link from '@material-ui/core/Link';
import MetaPanel from '../components/meta-panel';
import QuestCard from '../components/quest-card';
import Message from '../components/message';
import LinkResolver from '../utils/linkResolver';
import HtmlSerializer from '../utils/htmlSerializer';

const styles = (theme) => ({
    description: {
        color: theme.palette.secondary.main,
        padding: `3px 20px 20px`,
        [theme.breakpoints.up(`sm`)]: {
            padding: `5px 30px 20px`
        },
        backgroundColor: `#F3F3F3`,
        borderTop: `1px solid #000`,
        borderBottom: `1px solid #000`,
        fontSize: `1.15em`
    },
    otherLinks: {
        fontWeight: 600,
        textTransform: `uppercase`,
        '& a:hover': {
            textDecoration: `none`
        }
    },
    quests: {
        display: `flex`,
        flexWrap: `wrap`,
        margin: `30px 20px`,
        [theme.breakpoints.up(`sm`)]: {
            margin: `10px`
        },
        '& > div': {
            [theme.breakpoints.up(`sm`)]: {
                flexGrow: 1,
                flex: `1 1 45%`,
                margin: `10px`
            },
            [theme.breakpoints.up(`md`)]: {
                flexGrow: 1,
                flex: `1 1 30%`,
                margin: `15px`
            }
        },
        '& > div.no-quest-message': {
            flex: `none`,
            width: `100%`,
            margin: 0,
            padding: `0 0 20px`,
            [theme.breakpoints.up(`sm`)]: {
                padding: `10px 20px 0`
            }
        }
    }
});

const backgroundColours = [
    `#059A92`,
    `#B38755`,
    `#E2583E`,
    `#FDC821`,
    `#9CD2AE`
];
let count = 0;

class Realm extends React.Component {
    getDescriptionText = (realm) => {
        if (realm.description) {
            return RichText.asText(realm.description);
        }
        return RichText.asText(realm.name);
    };

    getDescription = (realm) => {
        if (realm.description) {
            return RichText.render(
                realm.description,
                LinkResolver,
                HtmlSerializer
            );
        }
        return <p>Learn all about {RichText.asText(realm.name)}</p>;
    };

    getQuests = (quests) => {
        let questCards = [];
        let count = 1;
        if (quests) {
            quests.forEach((node) => {
                if (node.quest) {
                    questCards.push(this.getQuestCard(node.quest, count++));
                }
            });
        }
        return questCards;
    };

    getRealms = (realms) => {
        if (realms) {
            return realms
                .map((node) =>
                    node.realm ? (
                        <Link href={`/${node.realm._meta.uid}`}>
                            {RichText.asText(node.realm.name)}
                        </Link>
                    ) : null
                )
                .reduce((prev, curr) => [prev, ' or ', curr]);
        }
        return null;
    };

    getQuestCard = (quest, count) => {
        if (quest) {
            return (
                <QuestCard
                    name={RichText.asText(quest.name)}
                    url={`/${quest._meta.uid}`}
                    id={count}
                    description={
                        quest.description
                            ? RichText.asText(quest.description)
                            : ``
                    }
                    icon={quest.icon}
                    backgroundColour={this.cycleBackgroundColour()}
                    date={quest._meta.lastPublicationDate}
                    difficulty={quest.difficulty}
                    magentoVersions={quest.magento_versions}
                    link={quest.coming_soon !== `Yes`}
                    key={quest._meta.uid}
                />
            );
        }
    };

    cycleBackgroundColour = () => {
        if (count === backgroundColours.length) {
            count = 0;
        }
        return backgroundColours[count++];
    };

    render() {
        const { classes, data } = this.props;
        const realm = data.prismic.realm,
            quests = this.getQuests(realm.quests);

        let name = RichText.asText(realm.name),
            description = this.getDescription(realm);

        return (
            <Layout>
                <SEO
                    title={name}
                    description={this.getDescriptionText(realm)}
                />
                <MetaPanel title={name} icon={realm.icon} pageType={`Realm`} />
                <div className={classes.description}>
                    {description}
                    <div className={classes.otherLinks}>
                        {`Done here? Go back to `}
                        <Link href={`/realms`}>{`all Realms`}</Link>
                        {realm.next_realms && (
                            <>
                                {` or why not continue your journey with`}
                                {` `}
                                {this.getRealms(realm.next_realms)}
                            </>
                        )}
                    </div>
                </div>
                <div className={classes.quests}>
                    {quests.length ? (
                        quests
                    ) : (
                        <div className={`no-quest-message`}>
                            <Message
                                type={`warning`}
                                title={`No quests found!`}
                            >
                                <p>
                                    This realm is still a work in progress,
                                    check back soon (or sign up to our
                                    newsletter below) for more updates!
                                </p>
                                <p>
                                    View{` `}
                                    <Link href={`/realms`} title={`Realms`}>
                                        all realms
                                    </Link>
                                    {` `}
                                    or go{` `}
                                    <Link href={`/`} title={`Home`}>
                                        back home
                                    </Link>
                                    ?
                                </p>
                            </Message>
                        </div>
                    )}
                    <div className={classes.flexPlaceholder} />
                    <div className={classes.flexPlaceholder} />
                    <div className={classes.flexPlaceholder} />
                    <div className={classes.flexPlaceholder} />
                </div>
            </Layout>
        );
    }
}

Realm.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles, { withTheme: true })(Realm));

export const pageQuery = graphql`
    query RealmBySlug($uid: String!) {
        prismic {
            realm(uid: $uid, lang: "en-gb") {
                name
                description
                icon
                next_realms {
                    realm {
                        __typename
                        ... on PRISMIC_Realm {
                            name
                            _meta {
                                uid
                            }
                        }
                    }
                }
                quests {
                    quest {
                        __typename
                        ... on PRISMIC_Quest {
                            name
                            description
                            icon
                            difficulty
                            coming_soon
                            magento_versions {
                                magento_version
                            }
                            _meta {
                                uid
                                lastPublicationDate
                            }
                        }
                    }
                }
            }
        }
    }
`;
